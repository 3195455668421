<template>
  <MasterDetail
    :cols="cols"
    :customResource="customResource"
    descriptionProperty="id"
    formTitle="Cadastro de Itens Projeto Externo"
    hasYearFilter
    :opts="opts"
    :selectedResourceId="selectedResourceId"
  >
    <span style="font-size: 16px">
      <strong>Valor utilizado da nota:</strong>
      {{ totalItens | toCurrency }}
      <small>de</small>
      {{ totalProjeto | toCurrency }}
    </span>
  </MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";
import yearsOptions from "@/helpers/yearsOptions.js";

export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols: function () {
      return [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "projetoextId",
          name: "Projeto",
          defaultValue: this.projetoId ? parseInt(this.projetoId) : undefined,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "projetos", key: "id", name: "nome_projeto" },
          editable: false,
          nowrap: true,
          truncate: true,
          width: "350px",
        },
        {
          key: "tipoitemId",
          name: "Tipo Item",
          type: this.$fieldTypes.SELECT,
          rel: { to: "tipoitem", key: "id", name: "tipo_item" },
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: "gasto_AnoBase",
          name: "Gasto Ano Base",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          editable: true,
          hideInform: false,
          colSize: 6,
        },
        {
          key: "ano_base",
          name: "Ano Base",
          type: this.$fieldTypes.SELECT,
          rel: { to: "yearsOptions", key: "value", name: "text" },
          colSize: 6,
        },
        {
          key: "observacao",
          name: "Observações",
          type: this.$fieldTypes.TEXTAREA,
          nowrap: true,
          truncate: true,
          width: "350px",
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ];
    },
    projetoId: function () {
      return this.$route.params.pId;
    },
    customResource: function () {
      const resource = this.apiResource(
        `/v1/faturamento/projext/projeto/${this.clientId}/${this.projetoId}/itens`
      );
      const self = this;
      return {
        ...resource,
        async get(...args) {
          try {
            const { itens, total_itens, total_projeto } = await resource.get(
              ...args
            );
            self.totalItens = parseFloat(total_itens) ?? 0;
            self.totalProjeto = parseFloat(total_projeto) ?? 0;
            return itens;
          } catch (error) {
            self.totalItens = 0;
            self.totalProjeto = 0;
            return [];
          }
        },
      };
    },
    resourceProjetos: function () {
      return this.apiResource(
        `/v1/faturamento/projext/projeto/${this.clientId}`
      );
    },
    resourceTipoItem: function () {
      return this.apiResource(`/v1/faturamento/projext/tipoItem`);
    },
    selectedResourceId: function () {
      return this.$route.query.itemId;
    },
  },
  created: function () {
    this.resourceProjetos.get().then((response) => {
      this.opts.projetos = response;
    });
    this.resourceTipoItem.get().then((response) => {
      this.opts.tipoitem = response;
    });
  },
  data: function () {
    return {
      totalItens: 0,
      totalProjeto: 0,
      opts: {
        projetos: [],
        tipoitem: [],
        yearsOptions,
      },
    };
  },
};
</script>
